export function getParams<T extends string>(
  search?: string
): Partial<{ [K in T]: string }>;
export function getParams<T extends object>(
  search?: string
): Partial<{ [K in keyof T]: string }>;
export function getParams(search?: string) {
  const paramsObj: { [key: string]: string } = {};
  new URLSearchParams(search).forEach((val, key) => (paramsObj[key] = val));
  return paramsObj;
}
