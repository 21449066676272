/** Format the text of a logger with a label and background color */
const formatLoggerText = (
  label: string,
  backgroundColor: string = '#000',
  ...args: any[]
) => {
  return [
    '%c %s %c',
    `color: #fff; background-color: ${backgroundColor};`,
    label.toUpperCase(),
    '',
    ...args,
  ];
};

/** Create a new logger item */
const createLoggerItem = (type: string, enabled: boolean, ...args: any[]) => {
  if (!enabled) {
    return;
  }

  switch (type) {
    case 'log': {
      return console.log(...formatLoggerText(type, '#333', ...args));
    }
    case 'warn': {
      return console.warn(...formatLoggerText(type, '#FC8622', ...args));
    }
    case 'error': {
      return console.error(...formatLoggerText(type, '#FF383D', ...args));
    }
    case 'info':
    default: {
      return console.info(...formatLoggerText(type, '#45A5F9', ...args));
    }
  }
};

/** Create a new logger object */
export const createLogger = (enabled: boolean) => {
  return {
    log: (...args: any[]) => createLoggerItem('log', enabled, ...args),
    warn: (...args: any[]) => createLoggerItem('warn', enabled, ...args),
    error: (...args: any[]) => createLoggerItem('error', enabled, ...args),
    info: (...args: any[]) => createLoggerItem('info', enabled, ...args),
  };
};

/** Styled console replacement for common methods */
export const logger = createLogger(process.env.NODE_ENV === 'development');
